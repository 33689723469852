.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-form-item {
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
}

.remove-padding-collapse {
  .ant-collapse-content-box {
    padding: 0px !important;
  }
}

.menu-popover {
  padding-left: 6px;

  .ant-popover-inner {
    box-shadow: none;
  }

  .ant-popover-inner {
    padding: 0px;
  }

  // padding-left: 0px;
  // width: 280px;
  // .ant-popover-inner {
  //   padding: 0px;
  // }
  // .ant-popover-inner-content {
  //   padding: 0px;
  // }
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  color: #80bc28 !important;
}

.date-overview {
  color: #44955b;
  border: 1px solid #44955b;
  width: 120px !important;

  .ant-picker-input > input {
    border-color: #44955b;
    color: #44955b;
  }

  .ant-picker-suffix {
    color: #44955b;
  }

  .ant-picker-clear {
    color: #44955b;
  }
  &:hover {
    .ant-picker-clear {
      color: #44955b;
    }
  }
}
