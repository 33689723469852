@tailwind base;
@tailwind utilities;

@import "./scroll.less";
@import "./utils.less";
@import './fonts.less';
@import "./preflight.less";

html * {
  font-family: "Kanit" !important;
}

html,
body,
#root {
  min-height: 100vh;
  min-width: 100vw;
}
body {
  margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
.ant-typography * {
  margin-bottom: 0px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 100000s ease-in-out 0s;
  // transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}
