::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  // background: #707070;
  // border: 0px none #d6d6d6;
  background: #7BB54E;
  border: 0px none #7BB54E;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  // background: #d6d6d6;
  background: #7BB54E;
}

::-webkit-scrollbar-thumb:active {
  // background: #d6d6d6;
  background: #7BB54E;
}

::-webkit-scrollbar-track {
  background: #dbdbdb;
  border: 0px none #d6d6d6;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  // background: #666666;
  background: #dbdbdb;
}

::-webkit-scrollbar-track:active {
  // background: #333333;
  background: #dbdbdb;
}

::-webkit-scrollbar-corner {
  background: transparent;
}